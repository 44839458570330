.card_margins {
  margin: 15px;
  

}

/* entire container, keeps perspective */
.flip-container {
	perspective: 1000px;


}

/* flip the pane when hovered */
.flip-container:hover .flipper, .flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.flip-container, .front, .back {
	width: 300px;
	height: 400px;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
  
}

/* flip speed goes here */
.flipper {
	transition: 1.0s;
	transform-style: preserve-3d;
  
	position: relative;
}

/* hide back of pane during swap */
.front, .back {
	backface-visibility: hidden;
  
	position: absolute;
	top: 0;
	left: 0;
}

/* front pane, placed above back */
.front {
	z-index: 2;
	/* for firefox 31 */
	transform: rotateY(0deg);
  border: 2px solid lime;
  background: #012910;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;



}

/* back, initially hidden pane */
.back {
	transform: rotateY(180deg);
  border: 2px solid lime;
  background: #012910;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;





}

.project_name {
  font-size: 20px;
}

.project_dates {
  font-size: 12px;
}

.project_image {
  width: 200px;
  border-radius: 20px;
  margin: 20px;
}

.project_description{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
}

.bullet {
  padding-block: 5px;
}




.info_icon {
  position: absolute;
  font-size: 25px;
  line-height: 30px;
  bottom: 5px;
  right: 10px;
}