.blank {
  color: black;
}

@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');
.matrix_font {
  font-family: "Electrolize", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: lime;
  text-decoration: none;
  line-height: 1.4;
  user-select: none;
  
}

#nameplate {
  font-size: 61.44px;
  line-height: 1;
}

#matrix_headshot_img {
  border: 2px solid green;  
  border-radius: 10px; 
  user-select: none;
  height: 250px;
  width: 250px;
  background: url("./matrix_headshot.png");
  background-size: contain;
  transition: border-radius 0.5s, background-image 0.5s; 

}
#matrix_headshot_img:hover {

  background: url("./matrix_headshot_clear.png");
  background-size: contain;
  transition: border-radius 0.5s, background-image 0.5s; 
  
}

.intro_text {
  font-size: 12.288px;
  padding: 1.536px;
  text-align: center;

}

.matrix_reference {
  color: darkgreen;
  font-size: 7.68px;
  line-height: 3;

}


.gradient_text {
  background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(50,205,50,1) 25%, rgba(132,244,112,1) 30%, rgba(50,205,50,1) 35%, rgba(2,0,36,0) 50%, rgba(50,205,50,1) 75%, rgba(132,244,112,1) 85%, rgba(50,205,50,1) 95%);
  background-clip: text !important; /* Clip the gradient to the text */
  -webkit-background-clip: text; /* Clip the gradient to the text */
  -webkit-text-fill-color: transparent; /* Hide the text color */
}

.a {
  cursor: pointer;
}

.section_header{
  font-size: 30.72px;
  margin: 10px;
}

#educationBox {
  margin: 20px;
  width: 40%;
  border: 2px solid lime;
  background: #012910;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

#gt_logo {
  height: 90px;
  background-color: white;
  border-radius: 20px;
  margin-right: 30px;
  border: 2px solid green;
}

.category_header{
  font-size: 23px;
}

.experience_row_labeled{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.experience_row{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  padding-right: 153px;
}

.sideways_text {
  transform: rotate(-90deg);
  transform-origin: center; /* Adjust the transform origin as needed */
  white-space: nowrap; /* Prevent text wrapping */
  text-align: center;
  align-self: center;
  height: 0px;
  width: 150px;
}

.sideways_text_CW {
  /* position: absolute;
  left: 75%; */
  transform: rotate(90deg);
  transform-origin: center; /* Adjust the transform origin as needed */
  white-space: nowrap; /* Prevent text wrapping */
  text-align: center;
  align-self: center;
  height: 0px;
  color: darkgreen;
  font-size: 18px;
  max-height: 10px;
  max-width: 10px;
}


.skills_row{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 120px;
}

.skill {
  height: 100px;
  width: 110px;
  border-radius: 20px; 
}

.skill_image {
  width: 75px;
  height: 75px;
}

.skill:hover {
  transform: translate3D(0,-1px,0) scale(1.07);
}

.download_logo {
  height: 50px;
  margin-right: 20px;
  transform: rotateZ(-90deg);
}

.filename {
  font-size: 12px;
  text-align: center;

}

.contactBox {
  margin: 20px;
  border: 2px solid lime;
  background: #012910;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 75px;
  text-decoration: none;

}

.contact_logo {
  height: 70px;
  margin-right: 20px;
}